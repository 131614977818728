<template>
    <div ref="dropWrapper">
        <a-dropdown 
            :getPopupContainer="getDropContainer"
            :trigger="['contextmenu']">
            <slot name="fileItem">
                <a-button     
                    icon="menu"
                    type="link">
                </a-button>
            </slot>
            <a-menu 
                slot="overlay">
                <a-menu-item 
                    key="detail"
                    @click="visible.detail = true; openFileDetail()">
                    <i class="fi fi-rr-info mr-2"></i>
                    <span>{{ $t('files.info') }}</span>
                </a-menu-item>
                <template v-if="sourceId || rootId">
                    <a-menu-item 
                        v-if="isTrash"
                        key="restore_from_trash"
                        @click="openRestoreModal()">
                        <i class="fi fi-rr-time-past mr-2"></i>
                        <span>{{ $t('files.restore') }}</span>
                    </a-menu-item>
                    <a-menu-item key="download" class="flex items-center">
                        <div 
                            v-if="isFolder"
                            @click="downloadFolder">
                            <i class="fi fi-rr-download mr-2"></i>
                            <span>{{ $t('files.download_as_zip') }}</span> 
                        </div>
                        <a 
                            v-else
                            download
                            target="_blank"
                            :href="file.path">
                            <i class="fi fi-rr-download mr-2"></i>
                            <span>{{ $t('files.download') }}</span> 
                        </a>
                    </a-menu-item>
                    <a-menu-item 
                        v-if="isFounder && !(isMyFiles && !isFolder)"
                        key="rename" 
                        class="flex items-center"
                        @click="visible.rename = true">
                        <i class="fi fi-rr-pencil mr-2"></i>
                        {{ $t('files.edit') }}
                    </a-menu-item>
                    <template v-if="!isFolder && !appType">
                        <a-menu-item 
                            key="share" 
                            class="flex items-center"
                            @click="share()">
                            <i class="fi fi-rr-share mr-2"></i>
                            {{ $t('files.share') }}
                        </a-menu-item>
                        <!--<a-menu-item 
                            key="task" 
                            class="flex items-center"
                            @click="createTask()">
                            <i class="fi fi-rr-add mr-2"></i>
                            {{ $t('files.create_task') }}
                        </a-menu-item>-->
                        <a-menu-item
                            v-if="file.is_image || file.is_video || file.extension === 'pdf'" 
                            key="openInNewTab">
                            <a 
                                target="_blank"
                                :href="file.path">
                                <i class="fi fi-rr-arrow-up-right-from-square mr-2"></i>
                                <span>{{ $t('files.open_in_new_tab') }}</span>
                            </a>
                        </a-menu-item>
                    </template>
                </template>
                <a-menu-item
                    v-if="!file.is_dynamic && isFounder" 
                    key="delete"
                    @click="confirmDelete()">
                    <i class="fi fi-rr-trash mr-2"></i>
                    <template v-if="!sourceId && !rootId">
                        <span>{{ $t('files.detach') }}</span>
                    </template>
                    <template v-else>
                        <span>{{ $t('files.delete') }}</span>
                    </template>
                </a-menu-item>
                <template v-if="sourceId || rootId">
                    <a-menu-item
                        v-if="isAttaching" 
                        key="attachFile"
                        @click="confirmUpload()">
                        <i class="fi fi-rr-add-document mr-2"></i>
                        <span>{{ $t('files.pin') }}</span>
                    </a-menu-item>
                    <!-- 'doc', 'docx', 'pdf', 'xls', 'xlsx' -->
                    <a-menu-item
                        v-if="['pdf'].includes(file.extension)"
                        key="previewDoc"
                        @click="visible.docPreview = true">
                        <i class="fi fi-rr-expand mr-2"></i>
                        <span>Предварительный просмотр</span>
                    </a-menu-item>
                </template>
            </a-menu>

        </a-dropdown>
        <a-modal 
            v-model="visible.detail"
            :getContainer="getDropContainer"
            :footer="null"
            :title="$t('files.info')">
            <div class="detail_file_name">
                <span class="font-semibold">
                    {{ $t('files.name') }}:
                </span>
                <!-- <a-input 
                    v-if="isFounder && !(isMyFiles && !isFolder)"
                    v-model="newFileName" 
                    class="mt-1 mb-2"
                    placeholder="Имя папки" /> -->
                <span>
                    {{ this.file.name }}
                </span>                
            </div>
            <div class="detail_file_desc">
                <span class="font-semibold">
                    {{ $t('files.description') }}:
                </span>
                <!-- <a-textarea 
                    v-if="isFounder && !(isMyFiles && !isFolder)"
                    v-model="newFileDesc" 
                    class="mt-1 mb-2"
                    placeholder="Описание" 
                    :rows="4" /> -->
                <span>
                    {{ this.file.description }}
                </span>                
            </div>
            <template v-if="!isFolder">
                <div class="detail_file_size">
                    <span class="font-semibold">
                        {{ $t('files.size') }}:
                    </span>
                    <span>
                        {{ getKilobyteSize }} КБ
                    </span>                
                </div>
                <div class="detail_file_extension">
                    <span class="font-semibold">
                        {{ $t('files.extension') }}:
                    </span>
                    <span>
                        {{ this.file.extension }}
                    </span>                
                </div>
                <div class="detail_file_type">
                    <span class="font-semibold">
                        {{ $t('files.file_type') }}:
                    </span>
                    <span>
                        {{ this.file.file_type.name }}
                    </span>                
                </div>
                <div class="mt-2 font-semibold">
                    Связанные объекты:
                </div>
                <template v-if="relatedLoading">
                    <div class="my-4 flex justify-center">
                        <a-spin />
                    </div>
                </template>
                <template v-else-if="Object.keys(relatedObjects).length">
                    <ul class="pl-4 list-disc">
                        <li 
                            v-for="relatedObjectsByType, objectType in relatedObjects"
                            :key="objectType">
                            <span class="font-semibold">{{ getRelatedCategoryName(objectType) }}</span>
                            <ul class="pl-4">
                                <li 
                                    v-for="relatedObject, index in relatedObjectsByType"
                                    :key="index">
                                    <div 
                                        v-if="checkRelatedUrl(relatedObject)"
                                        class="cursor-pointer"
                                        @click="getRelatedUrl(relatedObject)">
                                        <span>{{ getRelatedObjectType(relatedObject).name }}: </span>
                                        <span class="blue_color">{{ textCrop(getRelatedObjectStringView(relatedObject), 30) }}</span>
                                    </div>
                                    <div v-else>
                                        <span>{{ getRelatedObjectType(relatedObject).name }}: </span>
                                        <span>{{ textCrop(getRelatedObjectStringView(relatedObject), 30) }}</span>
                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </template>
                <template v-else>
                    <span>Нет</span>
                </template>
            </template>
            <template
                ref="detailModalFooter"
                slot="footer">
                <a-button
                    @click="visible.detail = false">
                    {{ $t('files.cancel') }}
                </a-button>
                <a-button
                    type="primary"
                    @click="isFileChanged && rename(); visible.detail = false">
                    {{ $t('files.save') }}
                </a-button>
            </template>
        </a-modal>

        <a-modal 
            v-model="visible.rename" 
            title="Изменить имя или описание"
            :getContainer="getDropContainer"
            cancelText="Отмена" 
            @ok="rename()"
            okText="Подтвердить">
            <a-input 
                v-model="newFileName" 
                placeholder="Имя папки" />
            <a-textarea 
                v-model="newFileDesc" 
                class="mt-2"
                placeholder="Описание" 
                :rows="4" />
        </a-modal>

        <a-modal 
            v-model="visible.restore" 
            :width="700"
            :getContainer="getDropContainer"
            class="file_restore"
            title="Восстановить"
            cancelText="Отмена" 
            @ok="restoreTo"
            okText="Восстановить в текущую">
            <Files
                :showFileCreate="false"
                :isRestoring="true"
                :restoreDest="restoreDest"
                :isMyFiles="true"
                :isFounder="true"
                :isStudent="true" />
        </a-modal>
        <a-modal
            v-if="!isFolder"
            title="Просмотр файла"
            :visible="visible.docPreview"
            :getContainer="getDropContainer"
            :destroyOnClose="true"
            class="doc_view_modal"
            :width="windowWidth"
            :dialog-style="{ 
                top: '0px', 
                left: '0px', 
                right: '0px', 
                bottom: '0px' 
            }"
            :footer="null"
            @cancel="visible.docPreview = false">
            <component 
                :is="docPreviewWidget"
                :file="file"/>
        </a-modal>
    </div>
</template>

<script>
import fileSourcesProps from '../mixins/fileSourcesProps'
import attachingSourcesProps from '../mixins/attachingSourcesProps'
import myRolesProps from '../mixins/myRolesProps'
import Files from './Files'
import SheetsViewer from './DocPreview/SheetsViewer.vue'

import eventBus from '@/utils/eventBus'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
    mixins: [fileSourcesProps, attachingSourcesProps, myRolesProps],
    components: {
        SheetsViewer,
        Files
    },
    props: {
        file: {
            type: Object,
            required: true
        },
        removeFiles: {
            type: Function,
            default: () => {}
        },
        restoreFiles: {
            type: Function,
            default: () => {}
        },
        isMyFiles: {
            type: Boolean,
            default: false
        },
        isTrash: {
            type: Boolean,
            default: false
        },
        setCurrentSource: {
            type: Function,
            default: () => {}
        },
        getDropContainer: {
            type: Function,
            default: () => document.body
        }
    },
    data() {
        return {
            newFileName: this.file.name,
            newFileDesc: this.file.description,
            visible: {
                detail: false,
                rename: false,
                docPreview: false,
                restore: false
            },
            relatedLoading: false,
            restoreDest: [],
            relatedObjects: {}
        }
    },
    computed: {
        ...mapState({
            appType: state => state.appType
        }),
        windowWidth() {
            return this.$store.state.windowWidth
        },
        getKilobyteSize() {
            return (this.file.size / 1024).toFixed(1)
        },    
        isFolder() {
            return this.file.obj_type === 'folder'
        },
        isAttaching() {
            return !!this.attachingRootId
        },
        isFileChanged() {
            return (this.file.name !== this.newFileName) ||
                (this.file.description !== this.newFileDesc)
        },
        docPreviewWidget() {
            if(['pdf'].includes(this.file.extension))
                return () => import('./DocPreview/PDFViewer.vue')
            if(['doc', 'docx'].includes(this.file.extension))
                return () => import('./DocPreview/WordViewer.vue')
            if(['xls', 'xlsx'].includes(this.file.extension))
                return () => import('./DocPreview/SheetsViewer.vue')
            return false
        }
    },
    methods: {
        ...mapActions('files', [
            'renameFile', 
            'renameMyFile',
            'renameFolder', 
            'renameMyFolder', 
            'uploadFiles', 
            'downloadFolderAsZIP',
            'downloadMyFolderAsZIP'
        ]),
        ...mapMutations('files', ['ADD_FILE']),
        openPreviewModal() {
            this.visible.docPreview = true
        },
        confirmContainer() {
            return () => this.getDropContainer()
        },
        share() {
            this.$store.commit('share/SET_SHARE_PARAMS', {
                model: 'files.files',
                shareId: this.file.id,
                object: this.file,
                shareUrl: this.file.path,
                shareTitle: `Файл - ${this.file.name}`
            })
        },
        async createTask() {
            let query = Object.assign({}, this.$route.query)

            if(query && query.task) {
                this.$store.commit('task/CHANGE_TASK_SHOW', false)
                delete query.task
                await this.$router.push({query})
            }

            let form = {
                attachments: [this.file],
                reason_model: 'files',
                reason_id: this.file.id
            }

            this.$store.commit('task/SET_TASK_TYPE', 'task')
            eventBus.$emit('ADD_WATCH', {type: 'add_task', data: form})
        },
        async rename() {
            if(this.newFileName) {
                this.visible.rename = false
                try {
                    if(this.isFolder) {
                        if(this.isMyFiles)
                            await this.renameMyFolder({
                                rootId: this.rootId,
                                folderId: this.sourceId, 
                                fileId: this.file.id,
                                newFileName: this.newFileName ,
                                newFileDesc: this.newFileDesc,
                            })
                        else
                            await this.renameFolder({
                                rootId: this.rootId,
                                folderId: this.sourceId, 
                                fileId: this.file.id,
                                newFileName: this.newFileName ,
                                newFileDesc: this.newFileDesc,
                            })
                    }
                    else {
                        if(this.isMyFiles) {
                            await this.renameMyFile({
                                folderId: this.sourceId, 
                                fileId: this.file.id,
                                newFileName: this.newFileName ,
                                newFileDesc: this.newFileDesc,
                            })
                        } else {
                            await this.renameFile({
                                rootId: this.rootId,
                                folderId: this.sourceId, 
                                fileId: this.file.id,
                                newFileName: this.newFileName ,
                                newFileDesc: this.newFileDesc,
                            })
                        }
                    }
                } 
                catch(error) {
                    console.log(error)
                }
                finally {

                }
            }
        },
        async confirmUpload() {
            const file = this.file
            let rootId = this.rootId
            let folderId = this.isFolder ? this.sourceId : null
            if(this.attachingRootId) {
                rootId = this.attachingRootId 
                folderId = this.attachingSourceId
                folderId = (folderId && (folderId !== rootId)) ? folderId : null
            }
            try {
                this.fileLoading = true
                if(!this.isMyFiles || this.attachingRootId) {
                    await this.uploadFiles({
                        files: [file.id], 
                        rootId: rootId, 
                        folderId: folderId
                    })
                } else {
                    this.ADD_FILE({
                        data: file, 
                        key: 'my_files'
                    })
                }
                this.$message.success(`Файл "${file.name}" успешно загружен.`);
                
            } catch(e) {
                console.log(e)
                this.$message.error(this.$t('chat.error'))
            } finally {
                this.fileLoading = false
            }   
        },
        async downloadFolder() {
            try {
                let status 
                if(this.isMyFiles) {
                    status = await this.downloadMyFolderAsZIP({
                        rootId: this.rootId,
                        folderId: this.file.id
                    })
                } else
                    status = await this.downloadFolderAsZIP({
                        rootId: this.rootId,
                        folderId: this.file.id
                    })
                if(status.alreadyWorking)
                    this.$message.success(this.$t('files.archive_is_already_working'))
                else
                    this.$message.success(this.$t('files.archive_is_working'))
            } catch(error) {
                this.$message.error(this.$t('files.error'))
            }
        },
        confirmDelete() {
            const self = this
            let title = `Удалить${ self.isFolder ? ' папку' : ' файл', self.isTrash ? ' навсегда' : '' }?`
            let content = `Объект "${ self.file.name }" будет удален ${ self.isTrash ? 'без возможности восстановления' : '', 
            self.isFolder ? 'со всеми вложениями' : ''}`
            if (!this.sourceId && !this.rootId) {
                title = `Открепить файл?`
                content = `Объект "${ self.file.name }" будет откреплен`
            }
            this.$confirm({
                title: title,
                content: content,
                getContainer: this.confirmContainer(),
                okText: 'Да',
                okType: 'danger',
                cancelText: 'Нет',
                onOk() {
                    if (!self.sourceId && !self.rootId) {
                        eventBus.$emit(`detach_file`, self.file.id)
                    } else {
                        self.removeFiles(self.isFolder ? {folders: self.file} : {files: self.file})
                    }
                },
            })
        },
        restoreTo() {
            const file = !this.isFolder ? this.file : []
            const folder = this.isFolder ? this.file : []
            const dest = this.restoreFiles({
                files: file,
                folders: folder,
                dest: this.restoreDest[0]
            })
            this.restoreNotification(dest)
        },
        confirmRestore() {
            const self = this
            this.$confirm({
                title: `Вы уверены что хотите восстановить ${ self.isFolder ? 'папку' : 'файл'}?`,
                okText: 'Да',
                okType: 'primary',
                getContainer: this.confirmContainer(),
                cancelText: 'Нет',
                onOk() {
                    self.restoreFiles(self.isFolder ? {folders: self.file} : {files: self.file})
                },
            })
        },
        async openRestoreModal() {
            const res = await this.$http('/files/folders/')
            this.visible.restore = true
        },
        restoreNotification(dest) {
            const key = `open${Date.now()}`;
            this.$notification.open({
                message: `Объект "${this.file.name}" восстановлен`,
                getContainer: this.confirmContainer(),
                btn: h => {
                    return h(
                        'a-button',
                        {
                            props: {
                                type: 'primary',
                                size: 'default',
                            },
                            on: {
                                click: () => {
                                    if(dest === null)
                                        dest = 'my_files'
                                    this.setCurrentSource(dest, 'my_files')
                                    this.$notification.close(key)
                                },
                            },
                        },
                        'Перейти в папку',
                    );
                },
                placement: 'bottomRight',
                key,
                onClose: close,
            });
        },
        async openFileDetail() {
            try {
                this.visible.detail = true
                this.relatedLoading = true
                if(!this.isFolder) {
                    const { data } = await this.$http(`/files/${ this.file.id }/related_objects/`)
                    this.relatedObjects = Object.keys(data)
                        .filter(category => data[category].length)
                        .reduce((destObject, category) => {
                            return Object.assign(destObject, { 
                                [category]: data[category]
                            })
                        }, {})
                }
            } catch(error) {
                console.log(error)
            } finally {
                this.relatedLoading = false
            }
        },
        getRelatedObjectType(relatedObject) {
            switch(relatedObject.object_type) {
            case 'workgroups.WorkgroupModel':
                return {
                    name: 'Рабочая группа (проект)', 
                    url: {
                        name: 'viewGroup',
                        value: relatedObject.id
                    }
                }
            case 'comments.CommentModel':
                return { name: 'Комментарий' }
            case 'chat.MessageModel':
                return { 
                    name: 'Сообщение чата',
                    url: {
                        name: 'chat_id',
                        value: relatedObject.chat
                    }
                }
            case 'meetings.PlannedMeetingModel':
                return { name: 'Собрания' }
            case 'bpms_common.NewsModel':
                return { name: 'Новости' }
            case 'processes.FinancialApplicationModel':
                return { name: 'Финансовая заявка' }
            case 'tasks.TaskModel':
                return { 
                    name: 'Задачи',
                    url: {
                        name: 'task',
                        value: relatedObject.id
                    }
                }
            case 'catalogs.GoodsModel':
                return { name: 'Товары' }
            case 'crm.GoodsOrderModel':
                return { 
                    name: 'Заказ',
                    url: {
                        name: 'order',
                        value: relatedObject.id
                    }
                }
            }
        },
        getRelatedObjectTypeName(type) {
            switch(type) {
            case 'workgroups.WorkgroupModel':
                return 'Рабочая группа'
            }
        },
        checkRelatedUrl(relatedObject) {
            return this.getRelatedObjectType(relatedObject)?.url?.name || null
        },
        getRelatedUrl(relatedObject) {
            let query = Object.assign({}, this.$route.query)
            if(query[this.getRelatedObjectType(relatedObject).url.name] && Number(query[this.getRelatedObjectType(relatedObject).url.name]) !== this.getRelatedObjectType(relatedObject).url.value || !query[this.getRelatedObjectType(relatedObject).url.name]) {
                query[this.getRelatedObjectType(relatedObject).url.name] = this.getRelatedObjectType(relatedObject).url.value
                this.$router.push({query})
                this.visible.detail = false
            }
        },
        getRelatedCategoryName(category) {
            switch(category) {
            case 'attaches':
                return 'Прикреплен к'
            case 'galleries':
                return 'В галерее'
            case 'ckeditors':
                return 'В CKEditor'
            case 'workgroup_logo':
                return 'Логотип группы'
            case 'workgroup_gallery':
                return 'В галерее группы'
            default:
                return 'Используется в'
            }
        },
        getRelatedObjectStringView(relatedObject) {
            if(relatedObject.object_type === 'chat.MessageModel')
                return relatedObject.text
            if(relatedObject.object_type === 'crm.GoodsOrderModel')
                return relatedObject.counter
            if(relatedObject.object_type === 'comments.CommentModel')
                return relatedObject.text + ' от ' + this.$moment(relatedObject.created_at).format('DD-MM-YY HH:mm:ss')
            return relatedObject.name || relatedObject.string_view
        },
        textCrop(text, length) {
            
            if(text?.length > length)
                return text.slice(0, length) + '...'
            return text
        }
    }
}
</script>


<style lang="scss">
.file_restore {
    .filter_pop_wrapper {
        min-width: 0 !important;
    }
}
.doc_view_modal{
    .ant-modal-body{
        padding: 0px;
        height: calc(100% - 36px);
    }
    .ant-modal{
        padding: 0px;
        height: 100vh;
    }
    .ant-modal-content{
        height: 100%;
        border-radius: 0px;
    }
    .ant-modal-wrap{
        overflow: hidden;
    }
    .ant-modal-header{
        padding: 7px 18px;
        border-radius: 0px;
        border-bottom: 0px;
        .ant-modal-title{
            font-size: 14px;
        }
    }
    .ant-modal-close-x{
        height: 36px;
        width: 36px;
        line-height: 30px;
    }
}
</style>